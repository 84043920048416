import React, {Component} from 'react'
import Header from "../partials/header"
import Footer from "../partials/footer"
import {isBrandLoggedin, brandloginPath, logout} from "../services/auth";
import {navigate} from "gatsby";
import scan from "../images/scanimg.png";
import history from "../images/history.png";

class scanqr extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {

        if (!isBrandLoggedin()) {
            if (typeof window !== "undefined") {
                navigate('/brand-login')
            }
        } else {
            this.setState({loaderdd: false})
        }
    }


    render() {

        return (
            <main>
                <Header/>
                <div className="container">
                    <div className="col-md-12">
                        <div className="row mt-3">
                            <div className="col-md-12 text-right " >
                                    <span className="mb-2 cursor-pointer" onClick={event => {
                                        event.preventDefault();
                                        logout(() => navigate(`/brand-login`))
                                    }} style={{fontSize: '20px'}}>
                                        <span className="fas fa-sign-out-alt" style={{
                                            fontSize: '18px',
                                            color: '#ff3c7c'
                                        }}></span> Logout
                                    </span>
                            </div>
                            <div className="brandhome">
                                <span className="imagessection" onClick={() => navigate('/scan-qr')}>
                                    <img className="imgsize" src={scan}/><br/>
                                    Scan QR
                                </span>

                                <span className="imagessection" onClick={() => navigate('/history-brand')}>
                                    <img className="imgsize" src={history}/>
                                    <br/>
                                    History
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </main>

        )
    }
}

export default scanqr;

 










